import { AxiosError } from "axios";
import { categoryService } from "../../services/category/category.service";
import { ICategory } from "../../types/categories/categoryTypes";
import { IErrorResponse } from "../../types/serverResponse/types";
import { useFetch } from "../useFetch";
import { RoutesWithoutParams } from "../useFetch/getRoutes";
import { useTranslation } from "react-i18next";

const useCategory = () => {
    const { t } = useTranslation();

    const findAllCategory = () =>
        useFetch<ICategory[]>(RoutesWithoutParams.AllCategory, {
            initialValue: []
        });

    const qtyQuestionsByCategory = async (type: string) => {
        try {
            const response = await categoryService.qtyQuestionByCat(type);
            return response;
        } catch (e) {
            // console.log(e);
        }
    };

    const createCategory = async (payload: { name: string; color: string }) => {
        try {
            const response = await categoryService.create(payload);
            return response;
        } catch (e: unknown) {
            const error = e as IErrorResponse;
            throw new Error(`Code ${error.response.data.code}`);
        }
    };

    const updateCategory = async (idCategory: number, payload: { name: string; color: string }) => {
        try {
            const response = await categoryService.update(idCategory, payload);
            return response;
        } catch (e: unknown) {
            const error = e as IErrorResponse;
            throw new Error(`${error.response.data.code}`);
        }
    };

    const destroyCategory = async (idCategory: number) => {
        try {
            const response = await categoryService.destroy(idCategory);
            return response;
        } catch (e: unknown) {
            const error = e as IErrorResponse;
            throw new Error(
                `${error.response.data.code === "D001" ? t("error_D001_delete_category") : error.response.data.code}`
            );
        }
    };

    return {
        qtyQuestionsByCategory,
        createCategory,
        findAllCategory,
        updateCategory,
        destroyCategory
    };
};

export default useCategory;
