import { useSelector } from "react-redux";
import { ITest } from "../../types/test_created/testTypes";
import { useFetch } from "../useFetch";
import { routesWithParams } from "../useFetch/getRoutes";
import { IRootState } from "../../store";
import { testService } from "../../services/testService/testService.service";
import { IDataSubmitCreateTestBook, IDataSubmitCreateTestPayload } from "../../pages/Apps/types";
import { IErrorResponse } from "../../types/serverResponse/types";

const useTest = () => {
    const iduser = useSelector((state: IRootState) => state.userConfig.id);

    const findAll = () =>
        useFetch<ITest[]>(routesWithParams.findAllTestByUser(iduser), {
            initialValue: []
        });

    const findOneTest = async (idTest: string) => {
        try {
            const response = await testService.findOne(idTest);
            return response;
        } catch (e: unknown) {
            const error = e as IErrorResponse;
            throw new Error(`${error.response.data.code}`);
        }
    };

    const createTest = async (data: IDataSubmitCreateTestPayload) => {
        try {
            const response = await testService.create(data);
            return response;
        } catch (e) {
            // console.log(e);
        }
    };

    const createBookTestFunc = async (data: IDataSubmitCreateTestBook) => {
        try {
            const response = await testService.createBookTest(data);
            return response;
        } catch (e) {
            // console.log(e);
        }
    };

    return { findAll, findOneTest, createTest, createBookTestFunc };
};

export default useTest;
