import { useNavigate } from "react-router-dom";
import IconInfoCircle from "../../../components/Icon/IconInfoCircle";
import IconArchive from "../../../components/Icon/IconArchive";
import { useTranslation } from "react-i18next";
import { IQTests } from "../../../types/qTests/qTestsTypes";
import ChartfinalScore from "./ChartFinalScore";
import { Fragment, useEffect, useState } from "react";
import { useQuestions } from "../../../hooks";
import IconRestore from "../../../components/Icon/IconRestore";
import Markdown from "react-markdown";
import { IAnswer } from "../../../types/answers/answerType";
import IconChecks from "../../../components/Icon/IconChecks";
import IconX from "../../../components/Icon/IconX";

interface IProps {
    qTestData: IQTests[];
}

const FinishedTest = ({ qTestData }: IProps) => {
    // hooks
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { findAllQuestion } = useQuestions();
    const { data: questions, loading, refresh } = findAllQuestion();

    // state
    const [score, setScore] = useState({
        ommited: 0,
        correct: 0,
        incorrect: 0,
        score: 0
    });
    const [transformedQuestions, setTransformedQuestions] = useState([]);

    useEffect(() => {
        const ommited = qTestData.filter((element: IQTests) => !element.id_answer).length;
        const correct = qTestData.filter((element: IQTests) => element.correct).length;
        const incorrect = qTestData.filter(
            (element: IQTests) => !element.correct && element.id_answer
        ).length;
        const score = Math.floor((correct / qTestData.length) * 100);

        function defineScore() {
            setScore({
                ommited: ommited,
                correct: correct,
                incorrect: incorrect,
                score: score
            });
        }

        defineScore();
    }, [qTestData]);

    useEffect(() => {
        // filter only questions with qTestData.id_question === questions.id
        const filteredQuestions = questions.filter((question) =>
            qTestData.some((qTest) => qTest.id_question === question.id)
        );
        // map the filtered questions to include the qTestData properties
        const mappedQuestions = filteredQuestions.map((question) => {
            const qTest = qTestData.find((qTest) => qTest.id_question === question.id);
            return {
                ...question,
                ...qTest
            };
        });

        // verify if answer is correct or not and include property correct in the question object
        mappedQuestions.forEach((question) => {
            const qTest = qTestData.find((qTest) => qTest.id_question === question.id);
            if (qTest) {
                question.correct = question.id_answer === qTest.id_answer;
            }
        });

        setTransformedQuestions(mappedQuestions as any);
    }, [questions]);

    if (loading || transformedQuestions.length === 0)
        return (
            <div className="panel">
                <div className="w-full h-full flex items-center justify-center">
                    <div className="flex flex-col">
                        <div className="text-2xl font-semibold text-primary ml-2">
                            {t("congratulations")}
                        </div>
                        <div className="flex text-center items-center justify-center mt-2">
                            <span className="animate-spin">
                                <IconRestore className="size-5" />
                            </span>
                            <div className="ml-2 ">{t("calculating")}</div>
                        </div>
                    </div>
                </div>
            </div>
        );

    const renderAnswers = (element: any) => {
        return (
            <Fragment key={element.id}>
                {element.answers.map((answer: IAnswer) => {
                    return (
                        <div
                            className={`${
                                element.id_answer === answer.id &&
                                "border-2 rounded border-dashed border-black p-2"
                            }`}
                        >
                            <label className="flex mt-2 sm:mt-2">
                                <span
                                    className={`peer-checked:text-black dark:peer-checked:text-white peer-checked:font-bold`}
                                >
                                    {element.type === "closed" && (
                                        <Markdown className={"whitespace-pre-wrap"}>
                                            {answer.description}
                                        </Markdown>
                                    )}
                                    <div
                                        className={`${
                                            answer.correct ? "text-success" : "text-danger"
                                        }`}
                                    >
                                        <Markdown className={"whitespace-pre-wrap"}>
                                            {answer.tutor_explanation}
                                        </Markdown>
                                    </div>
                                </span>
                            </label>
                        </div>
                    );
                })}
            </Fragment>
        );
    };

    const renderQuestionList = (element: any, key: number) => {
        return (
            <Fragment key={key}>
                <div className="flex mb-5">
                    <div className="panel">
                        <div className="flex mb-2">
                            <div className="border p-2 rounded text-[12px]">
                                <span className="font-semibold">id:</span> {element.id}
                            </div>
                            <div className="border p-2 rounded text-[12px] ml-2">
                                {element.correct ? (
                                    <div className="flex items-center">
                                        <IconChecks className="text-success" />{" "}
                                        <span className="text-success">{t("correct")}</span>
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        <IconX className="text-danger" />{" "}
                                        <span className="text-danger">{t("incorrect")}</span>
                                    </div>
                                )}
                            </div>
                            <div className="border p-2 rounded text-[12px] ml-2">
                                <span className="font-semibold">{t("points")}</span>
                                {": "}
                                {element.type === "closed" && element.correct && <> 1/1</>}
                                {element.type === "closed" && !element.correct && <> 0/1</>}
                                {element.type === "open" && element.correct && <> 2/2</>}
                                {element.type === "open" && !element.correct && <> 0/2</>}
                            </div>
                        </div>
                        <div className="flex justify-center mb-2 mobile:w-full">
                            {Array.isArray(element.video_questions) &&
                                element.video_questions?.length > 0 && (
                                    <div>
                                        <iframe
                                            src={`https://www.youtube.com/embed/${element.video_questions[0].url}?si=b5uC6bXR7NR5a8uD`}
                                            title="YouTube video explanation"
                                            className="w-[560px] h-[315px] mobile:w-full mobile:h-full"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                )}
                        </div>
                        <div className="border border-x-0 border-t-0 p-2 rounded text-base mb-4">
                            <Markdown>{element.description}</Markdown>
                        </div>
                        <div>{renderAnswers(element)}</div>
                    </div>
                </div>
            </Fragment>
        );
    };

    console.log(transformedQuestions);

    return (
        <>
            <div className="flex w-full h-auto items-center justify-center">
                <div>
                    <div className="flex flex-wrap w-full justify-center mb-5">
                        <div className="border border-gray-500/20 rounded-md shadow-[rgb(31_45_61_/_10%)_0px_2px_10px_1px] dark:shadow-[0_2px_11px_0_rgb(6_8_24_/_39%)] p-6 pt-12 mt-8 relative">
                            <div className="bg-primary absolute text-white-light ltr:left-6 rtl:right-6 -top-8 w-16 h-16 rounded-md flex items-center justify-center mb-5 mx-auto">
                                <IconInfoCircle className="size-10" />
                            </div>
                            <h5 className="text-dark text-[25px] font-semibold mb-3.5 dark:text-white-light">
                                {t("congratulations")}
                            </h5>
                            <p className="text-white-dark text-[18px]  mb-3.5">
                                {t("message_test_finished")}
                            </p>
                            {score.score > 0 ? (
                                <>
                                    <ChartfinalScore
                                        ommited={score.ommited}
                                        correct={score.correct}
                                        incorrect={score.incorrect}
                                        score={score.score}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className="relative flex items-center border p-3.5 rounded before:inline-block before:absolute before:top-1/2 ltr:before:right-0 rtl:before:left-0 rtl:before:rotate-180 before:-mt-2 before:border-r-8 before:border-t-8 before:border-b-8 before:border-t-transparent before:border-b-transparent before:border-r-inherit text-danger bg-danger-light border-danger ltr:border-r-[64px] rtl:border-l-[64px] dark:bg-danger-dark-light">
                                        <span className="absolute ltr:-right-11 rtl:-left-11 inset-y-0 text-white w-6 h-6 m-auto">
                                            <IconInfoCircle />
                                        </span>
                                        <span className="ltr:pr-2 rtl:pl-2">
                                            {t("without_score")}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="items-center flex justify-center">
                        <button
                            onClick={() => navigate(`/apps/previous_tests`)}
                            className="btn btn-primary !mt-6"
                        >
                            <IconArchive className="mr-2" />
                            {t("return")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                {transformedQuestions.length > 0 && transformedQuestions.map(renderQuestionList)}
            </div>
        </>
    );
};

export default FinishedTest;
