import { ChangeEvent, Fragment, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";

import { useTranslation } from "react-i18next";
import { IAnswer } from "../../../types/answers/answerType";
import { ITest } from "../../../types/test_created/testTypes";
import { IQTests } from "../../../types/qTests/qTestsTypes";
import { IQuestion } from "../../../types/questions/questionsType";
import { IDataSubmit } from "../types";
import { LoadingData } from "../../Components";

import Markdown from "react-markdown";

interface IProps {
    index: number;
    dataSubmit: IDataSubmit;
    setDataSubmit: (data: IDataSubmit) => void;
    // setDataSubmit: React.Dispatch<React.SetStateAction<IDataSubmit>>;
    questionData: Partial<IQuestion>;
    testData: Partial<ITest>;
    qTestData: IQTests;
}

const QuestionTestWithouTutor = ({
    index,
    dataSubmit,
    setDataSubmit,
    questionData,
    testData,
    qTestData
}: IProps) => {
    // hooks
    const { t } = useTranslation();

    // states
    const [answers, setAnswers] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState<string | null>(null);

    const [textAnswer, setTextAnswer] = useState<string>("");

    // functions
    useEffect(() => {
        if (answers.length === 0) {
            const result = questionData.answers?.sort(() => Math.random() - 0.5);
            setAnswers(result as []);
        }
    }, [index, dataSubmit, setDataSubmit, questionData, testData]);

    useEffect(() => {
        setLoading(false);
    }, [answers]);

    const handleAnswer = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
        correct: boolean | number
    ) => {
        const { name, value } = e.target;

        setDataSubmit({
            ...dataSubmit,
            id_question: questionData.id,
            correct: correct as any,
            [name]: parseInt(value)
        });
    };

    if (loading) return <LoadingData />;

    const handleImageClick = (src: string) => {
        setModalImage(src);
        setModalOpen(true);
    };

    function renderAnswers(element: IAnswer, key: number) {
        return (
            <Fragment key={key}>
                <label className="flex mt-2 sm:mt-6 cursor-pointer">
                    <input
                        type="radio"
                        name="id_answer"
                        className={`form-radio peer`}
                        value={element.id}
                        defaultChecked={element.id === qTestData.id_answer ? true : false}
                        onChange={(e) => handleAnswer(e, element.correct)}
                    />
                    <span
                        className={`peer-checked:text-black dark:peer-checked:text-white peer-checked:font-bold`}
                    >
                        <Markdown className={"whitespace-pre-wrap"}>{element.description}</Markdown>
                    </span>
                </label>
            </Fragment>
        );
    }

    // render
    return (
        <div
            onClick={() => {
                if (modalOpen) setModalOpen(false);
            }}
        >
            <div className="flex-1 items-center justify-between flex-wrap gap-2">
                <div className="flex-col">
                    <div className="flex">
                        <div className="w-full-auto h-auto">
                            <div>
                                <Markdown
                                    className={"whitespace-pre-wrap rounded-md "}
                                    components={{
                                        img: ({ src, alt }) => (
                                            <img
                                                src={src || ""}
                                                alt={alt || ""}
                                                className="cursor-pointer"
                                                onClick={() => handleImageClick(src || "")}
                                            />
                                        )
                                    }}
                                >
                                    {questionData.description}
                                </Markdown>
                            </div>
                            {questionData.type === "closed" ? (
                                <div>
                                    {answers.length > 0 && (
                                        <div className="mt-2">{answers.map(renderAnswers)}</div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <textarea
                                        id="ctnTextarea"
                                        rows={5}
                                        name="openAnswer"
                                        className="form-textarea mb-5"
                                        placeholder={t("your_answer")}
                                        onChange={(e) =>
                                            setDataSubmit({
                                                ...dataSubmit,
                                                id_question: questionData.id,
                                                correct: true,
                                                id_answer: questionData.answers?.length
                                                    ? questionData.answers[0].id
                                                    : 0
                                            })
                                        }
                                        required
                                    ></textarea>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {modalOpen && modalImage && (
                <div className="fixed top-6 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="relative">
                        <img
                            src={modalImage}
                            alt="Modal Content"
                            className="w-[85%] h-[85%] mobile:w-full"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuestionTestWithouTutor;
