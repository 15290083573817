import { api } from "../../api";
import { RoutesWithoutParams, routesWithParams } from "../../hooks/useFetch/getRoutes";
import { ICategoryResponse } from "../../types/categories/categoryTypes";
import { IGenericResponse } from "../../types/responseGeneric/types";

export const categoryService = {
    qtyQuestionByCat: async (type: string) => {
        const response = await api.get(routesWithParams.questionByCategoryAndType(type));
        return response.data.data;
    },

    update: async (idcategory: number, payload: { name: string; color: string }): Promise<ICategoryResponse> => {
        const response = await api.put(routesWithParams.updateCategory(idcategory), payload);
        return response.data.data;
    },

    create: async (payload: { name: string; color: string }): Promise<IGenericResponse> => {
        const response = await api.post(RoutesWithoutParams.CreateCategory, payload);
        return response.data.data;
    },

    destroy: async (idcategory: number): Promise<IGenericResponse> => {
        const response = await api.delete(routesWithParams.deleteCategory(idcategory));
        return response.data.data;
    }
};
